.login-page {
    height: 100vh !important;
    width: 100vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  /* .login-page h1 {
  } */
  .login-form {
    background-color: #b9bcccb6;
    margin-left: -15px;
    padding: 30px;
  }
  
  .login-form h1 {
    text-align: center;
    font-weight: bold;
    margin: 30px;
    color: #344265;
  }
  
  .login-form .ant-form-item-label label {
    color: #344265 !important;
  }
  .login-form button {
    background-color: #344265;
    color: white;
    outline: none;
  }
  .login-form button:hover {
    background-color: black;
    color: white;
  }
  .login-form a {
    color: #344265 !important;
  }
  
  .login-form a:hover {
    color: black !important;
  }