.register-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right, #c6ffdd, #fbd786, #f7797d);
  }
  .register-form {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    width: 30%;
  }
  
  .register-form h2 {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    color: #b87333;
  }
  
  .register-form a {
    color: #b87333 !important;
  }
  
  .register-form a:hover {
    color: black !important;
  }
  
  .register-form button {
    background-color: #b87333;
    color: white;
  }
  
  .register-form input:focus {
    border: 1px solid #b87333 !important;
    color: #b87333;
  }
  .register-form button:hover {
    background-color: black;
    color: white;
  }